import React from "react";
import { graphql } from "gatsby";
import SectionParagraphs from "../components/shared/SectionParagraph/SectionParagraph";
import { Button, Container } from "react-bootstrap";

import Layout from "../components/Layout";
import { getIsUserAuthenticated } from "../services/auth";

const Training = ({data}) => {
  const { training: {edges} } = data;

  const { node: {paragraphs, title} } = edges[0];
  const { trainingTitle, trainingDescription } = data?.seo?.nodes[0];
  
  const isAuthenticated = getIsUserAuthenticated();

  return (
    <Layout>
      <Container className="fl-page">
        <SectionParagraphs  title={title}
                            description={trainingDescription}
                            paragraphs={paragraphs}>
        </SectionParagraphs>
        { !isAuthenticated ? <></> :
          <Button className="btn btn-success" target="_blank" href="https://training.florabank.org.au/login/index.php">Sign into Moodle</Button>
        }
        <br/><br/>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    training: allStrapiTraining {
        edges {
          node {
            title
            paragraphs {
                contents
                images {
                  url
                  name
                  width
                  height
                }
              }
          }
        }
      },
    seo: allStrapiSeo {
        nodes {
          trainingDescription
          trainingTitle
        }
      },
  }
`;

export default Training;
